.simple-floatingedges {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    height: 100%;
  }
  
  .simple-floatingedges .react-flow__handle {
    width: 8px;
    height: 8px;
    background-color: transparent;
  }
  
  .simple-floatingedges .react-flow__handle-top {
    top: -10px;
  }
  
  .simple-floatingedges .react-flow__handle-bottom {
    bottom: -10px;
  }
  
  .simple-floatingedges .react-flow__handle-left {
    left: -10px;
  }
  
  .simple-floatingedges .react-flow__handle-right {
    right: -10px;
  }
  
  /* .simple-floatingedges .react-flow__node-custom {
    background: #fff;
    border: 1px solid #1a192b;
    border-radius: 3px;
    color: #222;
    font-size: 12px;
    padding: 10px;
    text-align: center;
    width: 150px;
  } */
  