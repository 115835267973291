.edgeButton {
    cursor: pointer;
    pointer-events: all;
    stroke: #ffd600;
    fill: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .edgeButton:hover {
    fill: #f5f5f5;
  }
  
  .edgeButtonText {
    pointer-events: none;
    user-select: none;
    /* fill: #0984e3; */
    font-size: small;
  }
  
  .edgePath {
    fill: none;
    /* stroke: #0984e3; */
    stroke-width: 2;
  }
  
  .placeholderPath {
    stroke-width: 1;
    stroke-dasharray: 3 3;
    stroke: #bbb;
    fill: none;
  }
  
